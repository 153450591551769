"use client";

import { useEffect, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { LoaderCircle } from "lucide-react";
import { signIn } from "next-auth/react";
import { useTranslations } from "next-intl";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@components/common/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@components/common/card";
import { Checkbox } from "@components/common/checkbox";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@components/common/form";
import { Input } from "@components/common/input";
import { Separator } from "@components/common/separator";
import { ToastAction } from "@components/common/toast";
import { useNavigation } from "@hooks/use-navigation";
import { useToast } from "@hooks/use-toast";
import { capitalize, getCallbackUrl, getMenuURL } from "@utils";

import SignInThirdParty from "../sign-in-third-party";

const SignIn = ({
  isMobile,
  error,
  provider,
}: {
  isMobile: boolean;
  error?: string;
  provider?: string;
}): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showProviderError, setShowProviderError] = useState<boolean>(false);
  const t = useTranslations("authentication.signIn");
  const tToast = useTranslations("common.toast");
  const { Link, useRouter } = useNavigation();
  const { push } = useRouter();
  const { toast } = useToast();

  useEffect(() => {
    if (error && provider) {
      setShowProviderError(true);
    }
  }, [error, provider]);

  useEffect(() => {
    if (showProviderError) {
      toast({
        title: t("unauthorizedTitle"),
        description:
          provider === "credentials"
            ? t("unauthorizedDescriptionCredentials")
            : t("unauthorizedDescription", {
                provider: capitalize(provider),
              }),
        variant: "destructive",
      });
    }
  }, [showProviderError, provider, t, toast]);

  const signInSchema = z.object({
    email: z.string({ message: t("emailRequired") }).email(t("emailInvalid")),
    password: z
      .string({ message: t("passwordRequired") })
      .min(1, { message: t("passwordRequired") }),
    rememberMe: z.boolean().optional(),
  });

  const form = useForm<z.infer<typeof signInSchema>>({
    resolver: zodResolver(signInSchema),
    defaultValues: { email: "", password: "", rememberMe: false },
  });

  const onSubmit = (values: z.infer<typeof signInSchema>): void => {
    setIsLoading(true);
    signIn("credentials", {
      email: values.email,
      password: values.password,
      rememberMe: values.rememberMe,
      redirect: false,
    })
      .then((result) => {
        if (result && !result.error) {
          const callbackUrl = getCallbackUrl(result.url);
          if (callbackUrl) {
            push(callbackUrl);
          } else {
            push(getMenuURL("app"));
          }
        } else {
          form.setError("password", { message: t("submitError") });
          setIsLoading(false);
        }
      })
      .catch(() => {
        toast({
          title: tToast("errorTitle"),
          description: tToast("errorDescription"),
          variant: "destructive",
          action: (
            <ToastAction
              altText={tToast("tryAgain")}
              onClick={() => onSubmit(values)}
            >
              {tToast("tryAgain")}
            </ToastAction>
          ),
        });
        setIsLoading(false);
      });
  };

  return (
    <section>
      <Card>
        <Form {...form}>
          <form method="post" onSubmit={form.handleSubmit(onSubmit)}>
            <CardHeader>
              <h1 className="text-center text-xl font-bold md:text-2xl">
                {t("title")}
              </h1>
            </CardHeader>
            <CardContent className="space-y-4">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("emailLabel")}</FormLabel>
                    <FormControl>
                      <Input
                        type="email"
                        placeholder={t("emailPlaceholder")}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("passwordLabel")}</FormLabel>
                    <FormControl>
                      <Input
                        type="password"
                        placeholder={t("passwordPlaceholder")}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex items-center justify-between">
                <FormField
                  control={form.control}
                  name="rememberMe"
                  render={({ field }) => (
                    <FormItem className="flex flex-row items-center">
                      <FormControl>
                        <Checkbox
                          aria-label={t("rememberMeDescription")}
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                      <FormLabel className="!my-0 ml-2 cursor-pointer">
                        {t("rememberMeLabel")}
                      </FormLabel>
                    </FormItem>
                  )}
                />
              </div>
            </CardContent>
            <CardFooter className="flex flex-col gap-2">
              <Button type="submit" className="w-full" disabled={isLoading}>
                {isLoading && <LoaderCircle className="mr-1 animate-spin" />}
                {t("submit")}
              </Button>
              <p>
                {t.rich("dontHaveAccount", {
                  link: (message) => (
                    <Button
                      asChild
                      variant={"link"}
                      className="p-0 font-semibold"
                    >
                      <Link href={getMenuURL("signUp")}>{message}</Link>
                    </Button>
                  ),
                })}
              </p>
            </CardFooter>
          </form>
        </Form>
      </Card>
      <div className="my-4 flex items-center space-x-4">
        <Separator className="flex-1" />
        <span className="uppercase text-light-foreground">{t("or")}</span>
        <Separator className="flex-1" />
      </div>
      <SignInThirdParty isMobile={isMobile} />
    </section>
  );
};

export default SignIn;
